import * as React from 'react';
import { designSystemConfig, isThemeColor } from '@mentimeter/ragnar-dsc';
import { withTheme } from '@mentimeter/ragnar-react';
import type { DefaultIconT, IconT } from './Icon';
import { Svg } from './shared-components';

const ReactionIcon = ({
  size = 2,
  color = 'text',
  children,
  theme = designSystemConfig,
}: React.PropsWithChildren<DefaultIconT>) => (
  <Svg
    viewBox="0 0 24 24"
    height={typeof size === 'number' ? theme.fontSizes[size] || size : size}
    width={typeof size === 'number' ? theme.fontSizes[size] || size : size}
  >
    <g fill={isThemeColor(theme, color) ? theme.colors[color] : color}>
      {children}
    </g>
  </Svg>
);

const Icon = withTheme<DefaultIconT>(ReactionIcon);

export function CatReaction({ ...props }: IconT) {
  return (
    <Icon {...props}>
      <path d="M17.37 6.36A11.64 11.64 0 0012 5.28a11.64 11.64 0 00-5.37 1.08l-3.91-3.9a.6.6 0 00-1 .42v11.57c.27 6.36 7.12 7.76 9.5 8.05a6.35 6.35 0 001.63 0c2.38-.29 9.22-1.7 9.49-8.06V2.88a.6.6 0 00-1-.43z" />
    </Icon>
  );
}

export function HeartReaction({ ...props }: IconT) {
  return (
    <Icon {...props}>
      <path d="M23.24 8.76A8.35 8.35 0 0121 14.23a48 48 0 01-5.58 5.24l-2.56 2.2a1.31 1.31 0 01-1.74 0l-2.56-2.2A48 48 0 013 14.23 8.35 8.35 0 01.76 8.76 7 7 0 012.54 4 6.07 6.07 0 017 2a5.63 5.63 0 013.53 1.22A7.25 7.25 0 0112 4.71a7.25 7.25 0 011.43-1.49A5.63 5.63 0 0117 2a6.07 6.07 0 014.5 2 7 7 0 011.74 4.76z" />
    </Icon>
  );
}

export function ThumbsUpReaction({ ...props }: IconT) {
  return (
    <Icon {...props}>
      <path d="M6 10v10.66a.67.67 0 01-.67.67H2.67a.67.67 0 01-.67-.67V10a.67.67 0 01.67-.66h2.65A.67.67 0 016 10zm16 1.07a2 2 0 00-2-1.73h-5.37a11.51 11.51 0 00.67-3.93 2.77 2.77 0 00-2.78-2.72H12a.67.67 0 00-.66.66c0 1.54-.6 4.32-1.73 5.45a7.46 7.46 0 01-2.26 1.46v10a15.49 15.49 0 005.5 1.08h4.37a2 2 0 002-2.74 2 2 0 001.48-1.93 1.85 1.85 0 00-.15-.74 2 2 0 001-3.26 2 2 0 00.45-1.62z" />
    </Icon>
  );
}

export function ThumbsDownReaction({ ...props }: IconT) {
  return (
    <Icon {...props}>
      <path d="M18 14V3.34a.67.67 0 01.67-.67h2.65a.67.67 0 01.67.67V14a.67.67 0 01-.67.66h-2.64A.67.67 0 0118 14zM2 13a2 2 0 002 1.73h5.37a11.51 11.51 0 00-.67 3.93 2.77 2.77 0 002.78 2.72H12a.67.67 0 00.66-.66c0-1.54.6-4.32 1.73-5.45a7.46 7.46 0 012.26-1.46v-10a15.49 15.49 0 00-5.5-1.08H6.81a2 2 0 00-2 2.74 2 2 0 00-1.48 1.87 1.85 1.85 0 00.15.74 2 2 0 00-1 3.26A2 2 0 002 13z" />
    </Icon>
  );
}

export function QuestionReaction({ ...props }: IconT) {
  return (
    <Icon {...props}>
      <path d="M14.61 7.7c0-1.27-.9-2-2.5-2A3.17 3.17 0 009.45 7a1.42 1.42 0 01-1.84.42l-.85-.49a1.4 1.4 0 01-.45-2A7.06 7.06 0 0112.25 2c3.11 0 6.16 1.87 6.16 5.42s-3.6 5-4.45 7a1.35 1.35 0 01-1.26.81h-.91a1.4 1.4 0 01-1.21-2.11c1.25-2.12 4.03-3.31 4.03-5.42zm-5.06 12a2.34 2.34 0 112.34 2.3 2.34 2.34 0 01-2.34-2.34z" />
      {/* Extra bold <path d="M14.2 7.88c0-1-.63-1.59-2-1.59a2.84 2.84 0 00-2.43 1.27A1.29 1.29 0 018.09 8L6.47 7A1.25 1.25 0 016 5.29 7.36 7.36 0 0112.33 2c3.13 0 6.26 1.92 6.26 5.49s-3.39 5-4.21 6.59a1.27 1.27 0 01-1.12.67h-1.78a1.27 1.27 0 01-1.09-1.94c1.2-1.91 3.81-3.07 3.81-4.93zM9.39 19.39A2.61 2.61 0 1112 22a2.62 2.62 0 01-2.61-2.61z" /> */}
    </Icon>
  );
}

export function QfaIndicator({ ...props }: IconT) {
  return (
    <Icon {...props}>
      <path d="M9.32 15h4.93v1.53a1 1 0 01-1 1h-5a.5.5 0 00-.26.08l-4.08 2.62V18a.47.47 0 00-.48-.48H2.38a1 1 0 01-1-1V7.89a1 1 0 011-1h5v6.16A1.91 1.91 0 009.32 15zM21.61 3.77H9.32a1 1 0 00-1 1v8.38a1 1 0 001 1h6.74a.49.49 0 01.31.11l3.81 3.1v-2.82a.49.49 0 01.48-.48h.95a1 1 0 001-1V4.73a1 1 0 00-1-.96z" />
    </Icon>
  );
}

export function VoteIndicator({ ...props }: IconT) {
  return (
    <Icon {...props}>
      <path d="M12 2a4.58 4.58 0 104.57 4.57A4.58 4.58 0 0012 2zm6.18 13.35a6.62 6.62 0 00-5.08-2.52h-2.2a6.62 6.62 0 00-5.08 2.52 9.29 9.29 0 00-2.1 6 .61.61 0 00.55.65h15.46a.61.61 0 00.55-.65 9.29 9.29 0 00-2.1-6z" />
    </Icon>
  );
}

export function CommentIndicator({ ...props }: IconT) {
  return (
    <Icon {...props}>
      <path d="M8 16C8 17.6569 6.65685 19 5 19C3.34315 19 2 17.6569 2 16C2 14.3431 3.34315 13 5 13C6.65685 13 8 14.3431 8 16Z" />
      <path d="M3 19.5C3 20.3284 2.32843 21 1.5 21C0.671573 21 0 20.3284 0 19.5C0 18.6716 0.671573 18 1.5 18C2.32843 18 3 18.6716 3 19.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 5C5.91015 5 3 7.91015 3 11.5C3 15.0899 5.91015 18 9.5 18H17.5C21.0899 18 24 15.0899 24 11.5C24 7.91015 21.0899 5 17.5 5H9.5ZM13.5 13.5C14.3284 13.5 15 12.8284 15 12C15 11.1716 14.3284 10.5 13.5 10.5C12.6716 10.5 12 11.1716 12 12C12 12.8284 12.6716 13.5 13.5 13.5ZM19 12C19 12.8284 18.3284 13.5 17.5 13.5C16.6716 13.5 16 12.8284 16 12C16 11.1716 16.6716 10.5 17.5 10.5C18.3284 10.5 19 11.1716 19 12ZM9.5 13.5C10.3284 13.5 11 12.8284 11 12C11 11.1716 10.3284 10.5 9.5 10.5C8.67157 10.5 8 11.1716 8 12C8 12.8284 8.67157 13.5 9.5 13.5Z"
      />
    </Icon>
  );
}
