/* eslint react/button-has-type: 0 */
import * as React from 'react';
// eslint-disable-next-line eslint-custom-plugin/no-next-router
import type NextLink from 'next/link';
import { styled, filterValidProps } from '@mentimeter/ragnar-styled';
import { LoaderIcon, CrossIcon, CheckIcon } from '@mentimeter/ragnar-visuals';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { ButtonT, RagnarButtonState } from './types';
import { ERROR_STATE, LOADING_STATE, SUCCESS_STATE } from './constants';
import { variants } from './variants';

const ButtonContent = ({
  state,
  size = 'default',
  children,
  iconLeading,
  iconTrailing,
}: ButtonT) => {
  const IconLeading = iconLeading;
  const IconTrailing = iconTrailing;
  const iconSize = size === 'compact' ? 1 : 2;

  const stateIconMap = {
    [LOADING_STATE]: (
      <LoaderIcon
        size={iconSize}
        aria-label="Loading icon"
        color="currentColor"
      />
    ),
    [SUCCESS_STATE]: (
      <CheckIcon
        size={iconSize}
        aria-label="Success icon"
        color="currentColor"
      />
    ),
    [ERROR_STATE]: (
      <CrossIcon size={iconSize} aria-label="Error icon" color="currentColor" />
    ),
  };

  return (
    <>
      <span
        className={`${!state ? 'opacity-0' : 'opacity-100'} absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center transition-opacity`}
      >
        {state ? stateIconMap[state] : null}
      </span>
      {IconLeading && (
        <span
          data-testid="leading-icon-container"
          aria-hidden
          className={container({ state, classes: 'mr-1 ' })}
        >
          <IconLeading color="inherit" size={iconSize} />
        </span>
      )}
      <span
        data-testid="button-children"
        aria-hidden={Boolean(state)}
        className={container({ state, classes: '' })}
      >
        {children}
      </span>

      {IconTrailing && (
        <span
          data-testid="trailing-icon-container"
          aria-hidden
          className={container({ state, classes: 'ml-1 ' })}
        >
          <IconTrailing color="currentColor" size={iconSize} />
        </span>
      )}
    </>
  );
};

const container = ({
  state,
  classes,
}: {
  state: RagnarButtonState | undefined;
  classes: string;
}) =>
  cn(
    state ? 'opacity-0' : 'opacity-100',
    'flex flex-row items-center text-inherit transition-opacity',
    classes,
  );

const _Button = createUnstyledButton('a');

type NextLinkT = typeof NextLink;

export function createUnstyledButton(LinkComponent: 'a' | NextLinkT) {
  return React.forwardRef<
    HTMLAnchorElement | HTMLButtonElement | NextLinkT,
    ButtonT
  >(
    (
      {
        disabled,
        target,
        rel,
        href,
        onClick = () => {},
        children,
        autoFocus,
        size = 'default',
        type = 'button',
        'data-testid': testId,
        state,
        iconLeading,
        iconTrailing,
        ...rest
      },
      ref,
    ) => {
      const validProps = filterValidProps(rest);

      if (href) {
        return (
          <LinkComponent
            href={href}
            target={target}
            rel={rel}
            onClick={disabled ? (event) => event.preventDefault() : onClick}
            data-testid={testId}
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
            aria-disabled={disabled}
            role={disabled ? 'link' : undefined}
            as={undefined as any} // The type for "as" differ in NextLink but since it's deprecated we can just ignore it.
            {...validProps}
          >
            <ButtonContent
              state={state}
              size={size}
              children={children}
              iconLeading={iconLeading}
              iconTrailing={iconTrailing}
            />
          </LinkComponent>
        );
      }

      return (
        <button
          type={type}
          autoFocus={autoFocus}
          onClick={disabled ? undefined : onClick}
          disabled={disabled}
          data-testid={testId}
          ref={ref as React.ForwardedRef<HTMLButtonElement>}
          {...validProps}
        >
          <ButtonContent
            state={state}
            size={size}
            children={children}
            iconLeading={iconLeading}
            iconTrailing={iconTrailing}
          />
        </button>
      );
    },
  );
}

const FelaButton = styled(_Button, { displayName: 'Button' })(
  'kosmosSpacing',
  'width',
);

export const Button = React.forwardRef(
  (props: React.ComponentProps<typeof FelaButton>, ref) => {
    const {
      variant,
      state,
      size,
      disabled,
      iconTrailing,
      iconLeading,
      className,
      children,
      ...rest
    } = props;

    const VariantMap =
      state === SUCCESS_STATE
        ? 'positive'
        : state === ERROR_STATE
          ? 'negative'
          : variant;

    const hasState = Boolean(state);
    const isDisabled = disabled || hasState;

    return (
      <FelaButton
        ref={ref}
        className={cn(
          variants({
            variant: VariantMap,
            size,
          }),
          className,
        )}
        disabled={isDisabled}
        data-has-state={hasState}
        {...rest}
      >
        <ButtonContent
          state={state}
          size={size}
          children={children}
          iconLeading={iconLeading}
          iconTrailing={iconTrailing}
        />
      </FelaButton>
    );
  },
);
